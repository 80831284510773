import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    credentials: {
      access_token: null,
      exp: null
    },
    userInfo: {
      social_media: null,
      entry_date: null,
      abo_id: null,
      social_url: null,
      isLeader: null,
      name: null,
      platform: null,
      id: null,
      party_id: null,
      profile_image: null
    }
  },
  getters: {
  },
  mutations: {
    addUserInfo(state, payload) {
      state.userInfo = { ...payload };
    },
    setCredentails(state, payload) {
      state.credentials = payload
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
