
import { defineComponent, ref, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween"
import "@/assets/css/style.css";
import "@/assets/css/board.css";
import { Axios } from "axios";
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: "LeaderBoardView",
  components: {},
  setup() {
    const currentDate = ref(dayjs('2022-05-30'));
    const currentWeek = ref(1);
    const weekRangeTxt = ref('');
    const router = useRouter();
    const axios: Axios = inject("axios")!;
    const store = useStore()
    const ranks = ref([])
    const team = ref({})
    window.scrollTo(0, 0);

    if (currentDate.value.isBefore('2022-03-21 10:00:00')) {
      currentWeek.value = 0;
    } else if (currentDate.value.isBetween('2022-03-21 10:00:00', '2022-03-28 10:00:00', null , '[]')) {
      currentWeek.value = 1;
      weekRangeTxt.value = '5 - 11 มี.ค. 65'
    } else if (currentDate.value.isBetween('2022-03-28 10:00:00', '2022-04-04 10:00:00', null , '[]')) {
      currentWeek.value = 2;
      weekRangeTxt.value = '12 - 18 มี.ค. 65'
    } else if (currentDate.value.isBetween('2022-04-04 10:00:00', '2022-04-11 10:00:00', null , '[]')) {
      currentWeek.value = 3;
      weekRangeTxt.value = '19 - 25 มี.ค. 65'
    } else if (currentDate.value.isBetween('2022-04-11 10:00:00', '2022-04-18 10:00:00', null , '[]')) {
      currentWeek.value = 4;
      weekRangeTxt.value = '26 มี.ค. - 1 เม.ย. 65'
    } else if (currentDate.value.isBetween('2022-04-18 10:00:00', '2022-04-25 10:00:00', null , '[]')) {
      currentWeek.value = 5;
      weekRangeTxt.value = '2 - 8 เม.ย.65'
    } else if (currentDate.value.isBetween('2022-04-25 10:00:00', '2022-05-02 10:00:00', null , '[]')) {
      currentWeek.value = 6;
      weekRangeTxt.value = '9 - 15 เม.ย. 65'
    } else if (currentDate.value.isBetween('2022-05-02 10:00:00', '2022-05-09 10:00:00', null , '[]')) {
      currentWeek.value = 7;
      weekRangeTxt.value = '16 - 22 เม.ย. 65'
    } else if (currentDate.value.isBetween('2022-05-09 10:00:00', '2022-05-16 10:00:00', null , '[]')) {
      currentWeek.value = 8;
      weekRangeTxt.value = '23 - 29 เม.ย. 65'
    } else if (currentDate.value.isBetween('2022-05-16 10:00:00', '2022-05-23 10:00:00', null , '[]')) {
      currentWeek.value = 9;
      weekRangeTxt.value = '30 เม.ย. - 6 พ.ค. 65'
    } else if (currentDate.value.isBetween('2022-05-23 10:00:00', '2022-06-30 10:00:00', null , '[]')) {
      currentWeek.value = 11;
      weekRangeTxt.value = '7 - 13 พ.ค. 65'
    }

    if (currentWeek.value == 0) {
      router.push('/activity');
    }

    document.addEventListener("scroll", function () {
      const header: any = document.querySelector(".header");
      var top = window.scrollY;
      top > 0
        ? header.classList.add("sticky")
        : header.classList.remove("sticky");

      document.getElementById("profile-info")?.classList.remove("show");
      document.getElementById("profile-info-m")?.classList.remove("show");

      const bar = document.querySelector(".myteam-bar") as Element;
      var top = window.scrollY;
      (top > 0) ? bar.classList.add("show") : bar.classList.remove("show");
    });

    const showProfileInfo = () => {
      document.getElementById("profile-info")?.classList.toggle("show");
      document.getElementById("profile-info-m")?.classList.toggle("show");
    };

    const openMenu = (e: any) => {
      document.getElementById("mobile-menu")?.classList.toggle("active");
      document.getElementById("profile-area-m")?.classList.toggle("hide");
      document.getElementById("sub-menu")?.classList.toggle("show");
      document.body.classList.toggle("menu-open");
    };

    const toggleModal = (modalNo: number) => {
      document
        .querySelector(`#detail-modal-${modalNo}`)
        ?.classList.toggle("d-none");
      document.body.classList.toggle("modal-show");
    };

    const toggleContent = (e: any) => {
      let element = e.currentTarget as Element
      element.parentElement?.classList.toggle("show");
    }

    const showContent = (e: any) => {
      let element = e.currentTarget as Element      
       element.parentElement?.classList.toggle("show");
    }

    const init = async () => {
      if (currentWeek.value > 0) {

        if (!(currentWeek.value >= 10)) {
          return router.push("/activity"); 
        }
        
        try {
          let response = await axios.get(`/api/memberweek/rank`, {
            params: {
              weekno: currentWeek.value,
              party_id: store.state.userInfo.party_id,
              abo_id: store.state.userInfo.abo_id
            }
          })

          ranks.value = response.data.rank
          team.value = response.data.team[0]
          
        } catch (error) {
          alert('ไม่สามารถโหลดข้อมูลได้')
        } 
      }
    };

    init();

    return {
      showProfileInfo: showProfileInfo,
      openMenu: openMenu,
      toggleModal: toggleModal,
      toggleContent: toggleContent,
      showContent: showContent,
      currentDate: currentDate,
      currentWeek: currentWeek,
      weekRangeTxt: weekRangeTxt,
      ranks: ranks,
      team: team,
      dayjs: dayjs,
    };

  }
});
