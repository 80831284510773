
import { defineComponent, ref, inject } from "vue";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "@/assets/css/style.css";
import "@/assets/css/edit_profile.css";
import { useStore } from "vuex";
import { Axios, AxiosError, AxiosResponse } from "axios";
dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: "ProfileView",
  components: {},
  setup() {
    window.scrollTo(0, 0);
    const axios: Axios = inject("axios")!;
    const store = useStore();
    const currentDate = ref(dayjs());
    const socialURL = ref(store.state.userInfo.social_url);

    document.addEventListener("scroll", function () {
      const header: any = document.querySelector(".header");
      let top = window.scrollY;
      top > 0
        ? header.classList.add("sticky")
        : header.classList.remove("sticky");

      document.getElementById("profile-info")?.classList.remove("show");
      document.getElementById("profile-info-m")?.classList.remove("show");
    });

    const showProfileInfo = () => {
      document.getElementById("profile-info")?.classList.toggle("show");
      document.getElementById("profile-info-m")?.classList.toggle("show");
    };

    const choiceActive = (e: any, old: any, num: any) => {
      const input = e.currentTarget.querySelector("input");
      console.log(input);

      let customRadioActive = e.currentTarget.parentElement.querySelector(
        ".custom-radio-active"
      );

      if (input.checked) {
        customRadioActive.classList.remove("active-" + old);
        customRadioActive.classList.add("active-" + num);
      }
    };

    const switchInfo = (id: string, oldId: string) => {
      let infoText = document.querySelector(".information-text." + id);
      let oldInfoText = document.querySelector(".information-text." + oldId);

      infoText?.classList.remove("hidden");
      oldInfoText?.classList.add("hidden");
    };

    const getPartyInfo = async (party_id: string) => {
      return axios
        .get("/api/member/registerd", {
          params: {
            event_id: process.env.VUE_APP_EVENT_ID,
            party_id: party_id,
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((err: AxiosError) => {
          console.log("error", err.response);
          alert("เกิดข้อผิดพลาดบางอย่างขณะติดต่อกับเซิฟเวอร์");
        });
    };

    const submit = (e: any) => {
      document.getElementById('social-alert')?.classList.add('input-error-text');
      let el = e.currentTarget;
      el.disabled = true;

      console.log(socialURL.value);

      let addresses = ["https://www.facebook.com/",
        "facebook.com/",
        "www.facebook.com/",
        "https://facebook.com/",
        "http://facebook.com/",
        "http://www.facebook.com/",
        "http://m.facebook.com/",
        "https://m.facebook.com/",
        "m.facebook.com/",
        "https://web.facebook.com/",
        "http://web.facebook.com/",
        "web.facebook.com/",
        "https://l.facebook.com/",
        "https://lm.facebook.com/",
        "http://l.facebook.com/",
        "http://lm.facebook.com/",
        "l.facebook.com/",
        "lm.facebook.com/",
        "https://fb.me/",
        "http://fb.me/",
        "http://www.fb.me/",
        "https://www.fb.me/",
        "www.fb.me/",
        "fb.me/"
      ];

      let isWhitelisted = addresses.find(a => socialURL.value.startsWith(a));

      if (isWhitelisted || socialURL.value == "" ) {
        axios
        .post("/api/member/socialurl", {
          id: store.state.userInfo.id,
          social_url: socialURL.value,
        })
        .then(async (res: AxiosResponse) => {
          let profile_image = store.state.userInfo.profile_image;
          let partyInfo = await getPartyInfo(store.state.userInfo.party_id);
          if (partyInfo.team && partyInfo.user) {
            store.commit("addUserInfo", {
              ...partyInfo.user,
              profile_image: profile_image,
            });
            alert('บันทึกข้อมูลเรียบร้อยแล้ว')
          }
          el.disabled = false;
        })
        .catch((err: AxiosError) => {
          document.getElementById('social-alert')?.classList.remove('input-error-text');
          el.disabled = false;
        }); 
      } else {
        document.getElementById('social-alert')?.classList.remove('input-error-text');
        el.disabled = false;
      }
    };

    return {
      showProfileInfo: showProfileInfo,
      socialURL: socialURL,
      submit: submit,
      choiceActive: choiceActive,
      switchInfo: switchInfo,
      currentDate: currentDate,
      dayjs: dayjs,
    };
  },
});
