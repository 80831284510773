
import { defineComponent, ref, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import jwtDecode from "jwt-decode";
import { AxiosResponse, AxiosError, Axios } from "axios";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "../assets/css/style.css";
import "../assets/css/landing.css";
import "../assets/css/popup.css";

dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: "LoginView",
  components: {},
  setup(_, ctx) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const axios: any = inject("axios")!;
    const authSSO = process.env.VUE_APP_AUTH_ENDPOINT;
    let iframe = ref();
    let aboId = ref(null);
    let isLoaded = ref(false);
    let isUserInactive = ref(false);
    let members = ref([]);
    let accounts: any = ref([]);
    let selectedUser = ref(null);
    let selectedAccount = ref(null);
    let isRegistered = ref(true);
    let jwtInfo: any = ref({});

    document.documentElement.style.overflow = "hidden";

    const getPartyInfo = (party_id: string) => {
      return axios
        .get("/api/member/registerd", {
          params: {
            event_id: process.env.VUE_APP_EVENT_ID,
            party_id: party_id,
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((err: AxiosError) => {
          console.log("error", err.response);
          alert("เกิดข้อผิดพลาดบางอย่างขณะติดต่อกับเซิฟเวอร์");
        });
    };

    const getUserProfileImage = (party_id: string, abo_id: string) => {
      return axios
        .get("/api/external/amway/profile/image", {
          params: {
            abo_id: abo_id,
            party_id: party_id,
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((err: AxiosError) => {
          console.log("error", err.response);
          return null;
        });
    };

    const selectAccount = async () => {
      isLoaded.value = false;
      let accountId: any = selectedAccount.value;
      let account = accounts.value.find((m: any) => m.accountId == accountId)
      window.location.href = process.env.VUE_APP_ABO_ENDPOINT + `&scope=salesPlanAff=200aboNum=${account.accountId}partyId=${account.partyId}`
    }

    const selectUser = async () => {
      isLoaded.value = false;

      let partyId: any = selectedUser.value;
      let partyInfo = await getPartyInfo(partyId);

      if (
        partyInfo.user &&
        partyInfo.team &&
        partyInfo.team.members &&
        partyInfo.team.members !== null &&
        partyInfo.team.members.length == 4
      ) {
        let profileImage = await getUserProfileImage(
          partyId,
          partyInfo.user.abo_id
        );
        isLoaded.value = true;
        store.commit("addUserInfo", {
          ...partyInfo.user,
          profile_image: profileImage,
        });

        document.documentElement.style.overflow = "auto";
        if (dayjs().isSameOrAfter('2022-05-23 10:00:00')) {
          router.push("/final_result"); 
        } else if (dayjs().isSameOrAfter('2022-03-05')) {
          router.push("/activity"); 
        } else {
          router.push("/challenge"); 
        }
      } else {
        router.push("/register-failed");
        isRegistered.value = false;
        isLoaded.value = true;
      }
    };

    const getAccessToken = () => {
      if (
        route.query.error &&
        route.query.error == "invalid_scope" &&
        route.query.error_description &&
        route.query.error_description == "Unable to set scope, Multiple ABOShips Found" &&
        route.query.scope
      ) {
        let split = route.query.scope.toString().split(';')
        
        split.forEach(obj => {
          let splitObj = obj.split(/(=)/g)

          console.log(splitObj);
          
          for (let i = 0; i < splitObj.length; i++) {
            const el = splitObj[i];
            if (el == "=") {
              splitObj[i-1] = splitObj[i-1].replace(/ ([^ ]*)$/, '|' + '$1')
            }
          }

          let splitObjStr = splitObj.join('')
          let keyPair = splitObjStr.split('|')
          let memberObject: any = {};

          for (let i = 0; i < keyPair.length; i++) {
            const k = keyPair[i];
            if (k) {
              let keyValue = k.split('=')
              memberObject[keyValue[0]] = keyValue[1]

              if (keyPair.length == i+1) {
                accounts.value.push(memberObject)
              } 
            }
          }
        });
        
        selectedAccount.value = accounts.value[0].accountId;
        isLoaded.value = true;
        console.log(accounts.value);
        
      } else {
        setTimeout(() => {
          axios.get(authSSO, {
            withCredentials: true,
          }).then((res: AxiosResponse) => {
            if (res.data.id_token) {
              verifyIdToken(res.data.id_token)
            } else {
              window.location.href = process.env.VUE_APP_SSO_ENDPOINT;
            }
          }).catch((err: AxiosError) => {
            console.log(err.message);
            alert('เกิดข้อผิดพลาดบางอย่างขณะติดต่อกับเซิฟเวอร์')
          })
        }, 1000);
      }
    };

    const verifyIdToken = async (id_token: string) => {
      jwtInfo.value = jwtDecode(id_token);

      if (!jwtInfo.value.aboid && !route.query.access_token && !route.query.scope) {
        window.location.href = process.env.VUE_APP_ABO_ENDPOINT + `&scope=salesPlanAff=200`
        return;
      } else if (!jwtInfo.value.aboid && route.query.access_token && route.query.scope) {
        let scopeObject: any = {};
        let scope = route.query.scope.toString().split(' ')
        
        for (let i = 0; i < scope.length; i++) {
          const el = scope[i];
          let keyValue = el.split('=')
          scopeObject[keyValue[0]] = keyValue[1]
        }

        axios
          .get('/api/external/amway/abo/member', {
            params: {
              abo_id: scopeObject.aboNum,
            }
          })
          .then(async (res: AxiosResponse) => {
            if (!res.data.members || res.data.members.length == 0) {
              await router.push("/register-failed");
              return;
            }

            if (res.data.statusCd == 'Inactive') {
              isLoaded.value = true;
              isUserInactive.value = true;
              return;
            }

            aboId.value = res.data.id;
            members.value = res.data.members;
            selectedUser.value = res.data.members[0].id;
            isLoaded.value = true;
            store.commit("setCredentails", {
              access_token: id_token,
              exp: dayjs.unix(jwtInfo.value.exp).format(),
            });

            axios.defaults.headers['authorization'] = id_token
            
            if (res.data.members.length == 1) {
              selectUser();
            } 
          })
          .catch((err: AxiosError) => {
            console.log(err.response ? err.response.data : err.message);
            isLoaded.value = true;

            if (err.response && err.response.status == 401) {
              window.location.href = "https://amway.co.th";
            }
          });
      } else {
        axios
          .post("/api/external/amway/info", {
            amway_token: id_token,
          })
          .then(async (res: AxiosResponse) => {
            if (!res.data.members || res.data.members.length == 0) {
              await router.push("/register-failed");
              return;
            }

            if (res.data.statusCd == 'Inactive') {
              isLoaded.value = true;
              isUserInactive.value = true;
              return;
            }

            aboId.value = res.data.id;
            members.value = res.data.members;
            selectedUser.value = res.data.members[0].id;
            isLoaded.value = true;
            store.commit("setCredentails", {
              access_token: id_token,
              exp: dayjs.unix(jwtInfo.value.exp).format(),
            });

            axios.defaults.headers['authorization'] = id_token

            if (res.data.members.length == 1) {
              selectUser();
            }
          })
          .catch((err: AxiosError) => {
            console.log(err.response ? err.response.data : err.message);
            isLoaded.value = true;

            if (err.response && err.response.status == 401) {
              window.location.href = "https://amway.co.th";
            }
          });
      }
    };

    return {
      iframe: iframe,
      authSSO: authSSO,
      aboId: aboId,
      isLoaded: isLoaded,
      isUserInactive: isUserInactive,
      isRegistered: isRegistered,
      selectUser: selectUser,
      selectAccount: selectAccount,
      accounts: accounts,
      members: members,
      getAccessToken: getAccessToken,
      selectedUser: selectedUser,
      selectedAccount: selectedAccount
    };
  },
});
