
import { defineComponent } from "vue";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "@/assets/css/register-fail.css";

dayjs.extend(isSameOrAfter);

export default defineComponent({
  setup() {
    return {
      dayjs: dayjs
    }
  },
});
