
import { defineComponent, ref, inject } from "vue";
import { AxiosResponse, AxiosError } from "axios";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "@/assets/css/style.css";
import "@/assets/css/landing.css";
import "@/assets/css/landing_leader.css";
import "@/assets/css/popup.css";
import { useStore } from "vuex";
dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: "challengeView",
  components: {},
  setup() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    const store = useStore();
    const axios: any = inject("axios");
    const currentDate = ref(dayjs());
    let isLoaded = ref(false);
    let socialURL = ref(store.state.userInfo.social_url);
    let partyInfo: any = ref({
      team: {
        leader: {
          updateDate: "",
          social_media: "",
          entry_date: "",
          abo_id: "",
          social_url: "",
          isLeader: true,
          name: "",
          gender: "",
          createDate: "",
          platform: "",
          tel: "",
          id: "",
          party_id: "",
          age: "",
        },
        members: [],
        id: "",
        name: "",
      },
    });

    document.addEventListener("scroll", function () {
      const header: any = document.querySelector(".header");
      var top = window.scrollY;
      top > 0
        ? header.classList.add("sticky")
        : header.classList.remove("sticky");

      document.getElementById("profile-info")?.classList.remove("show");
      document.getElementById("profile-info-m")?.classList.remove("show");
    });

    const showProfileInfo = () => {
      document.getElementById("profile-info")?.classList.toggle("show");
      document.getElementById("profile-info-m")?.classList.toggle("show");
    };

    const getPartyInfo = (party_id: string) => {
      return axios
        .get("/api/member/registerd", {
          params: {
            event_id: process.env.VUE_APP_EVENT_ID,
            party_id: party_id,
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((err: AxiosError) => {
          console.log("error", err.response);
          alert("เกิดข้อผิดพลาดบางอย่างขณะติดต่อกับเซิฟเวอร์");
        });
    };

    const getUserProfileImage = (party_id: string, abo_id: string) => {
      return axios
        .get("/api/external/amway/profile/image", {
          params: {
            abo_id: abo_id,
            party_id: party_id,
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((err: AxiosError) => {
          console.log("error", err.response);
          return null;
        });
    };

    const submit = (e: any) => {
      document.getElementById("name-alert")?.classList.add("input-error-text");
      let el = e.currentTarget;
      el.disabled = true;

      let addresses = ["https://www.facebook.com/",
        "facebook.com/",
        "www.facebook.com/",
        "https://facebook.com/",
        "http://facebook.com/",
        "http://www.facebook.com/",
        "http://m.facebook.com/",
        "https://m.facebook.com/",
        "m.facebook.com/",
        "https://web.facebook.com/",
        "http://web.facebook.com/",
        "web.facebook.com/",
        "https://l.facebook.com/",
        "https://lm.facebook.com/",
        "http://l.facebook.com/",
        "http://lm.facebook.com/",
        "l.facebook.com/",
        "lm.facebook.com/",
        "https://fb.me/",
        "http://fb.me/",
        "http://www.fb.me/",
        "https://www.fb.me/",
        "www.fb.me/",
        "fb.me/"
      ];

      let isWhitelisted = addresses.find(a => socialURL.value.startsWith(a));

      if (isWhitelisted || socialURL.value == "" ) {
        axios
          .post("/api/member/socialurl", {
            id: store.state.userInfo.id,
            social_url: socialURL.value,
          })
          .then(async (res: AxiosResponse) => {
            let profile_image = store.state.userInfo.profile_image;
            let partyInfo = await getPartyInfo(store.state.userInfo.party_id);
            if (partyInfo.team && partyInfo.user) {
              store.commit("addUserInfo", {
                ...partyInfo.user,
                profile_image: profile_image,
              });
              alert("บันทึกข้อมูลเรียบร้อยแล้ว");
            }
            el.disabled = false;
          })
          .catch((err: AxiosError) => {
            document.getElementById("name-alert")?.classList.remove("input-error-text");
            el.disabled = false;
          });
      } else {
        el.disabled = false;
        document.getElementById("name-alert")?.classList.remove("input-error-text");
      }
    };

    const openMenu = (e: any) => {
      document.getElementById("mobile-menu")?.classList.toggle("active");
      document.getElementById("profile-area-m")?.classList.toggle("hide");
      document.getElementById("sub-menu")?.classList.toggle("show");
      document.body.classList.toggle("menu-open");
    };

    const toggleModal = (id: string) => {
      document.querySelector("#" + id)?.classList.toggle("d-none");
      document.body.classList.toggle("popup-show");
    };

    const init = async () => {
      if (store.state.userInfo.party_id) {
        partyInfo.value = await getPartyInfo(store.state.userInfo.party_id);

        // transaform username
        partyInfo.value.team.leader.name = partyInfo.value.team.leader.name.replace(" ", "\n");
        for (let i = 0; i < partyInfo.value.team.members.length; i++) {
          const member = partyInfo.value.team.members[i];
          member.name = member.name.replace(" ", "\n");
        }

        // get user images async
        partyInfo.value.team.members.forEach(async (member: any, i: number) => {
          if (i == 0) {
            partyInfo.value.team.leader.image = await getUserProfileImage(
              partyInfo.value.team.leader.party_id,
              partyInfo.value.team.leader.abo_id
            );
          }
          member.image = await getUserProfileImage(
            member.party_id,
            member.abo_id
          );
        });

        setTimeout(() => {
          isLoaded.value = true;
        }, 2000);
      } else {
        window.location.href = "https://amway.co.th";
      }
    };

    init();

    return {
      showProfileInfo: showProfileInfo,
      getUserProfileImage: getUserProfileImage,
      openMenu: openMenu,
      toggleModal: toggleModal,
      isLoaded: isLoaded,
      socialURL: socialURL,
      submit: submit,
      partyInfo: partyInfo,
      currentDate: currentDate,
      dayjs: dayjs,
    };
  },
});
