
import { defineComponent, ref, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import "@/assets/css/style.css";
import "@/assets/css/board.css";
import { Axios, AxiosError, AxiosResponse } from "axios";
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

export default defineComponent({
  name: "TeamScoreView",
  components: {},
  setup() {
    const currentDate = ref(dayjs());
    const currentWeek = ref(1);
    const currentRank = ref(0);
    const router = useRouter();
    const axios: Axios = inject("axios")!;
    const store = useStore();
    const team: any = ref({});
    const finalWeek: any = ref({})
    const hasFinalWeek = ref(false);
    const specialReward: any = ref({});
    const weekTxt = [
      "5 - 11 มี.ค. 65",
      "12 - 18 มี.ค. 65",
      "19 - 25 มี.ค. 65",
      "26 มี.ค. - 1 เม.ย. 65",
      "2 - 8 เม.ย.65",
      "9 - 15 เม.ย. 65",
      "16 - 22 เม.ย. 65",
      "23 - 29 เม.ย. 65",
      "30 เม.ย. - 6 พ.ค. 65",
      "7 - 13 พ.ค. 65",
    ];
    window.scrollTo(0, 0);

    if (currentDate.value.isBefore("2022-03-21 10:00:00")) {
      currentWeek.value = 0;
    } else if (
      currentDate.value.isBetween(
        "2022-03-21 10:00:00",
        "2022-03-28 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 1;
    } else if (
      currentDate.value.isBetween(
        "2022-03-28 10:00:00",
        "2022-04-04 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 2;
    } else if (
      currentDate.value.isBetween(
        "2022-04-04 10:00:00",
        "2022-04-11 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 3;
    } else if (
      currentDate.value.isBetween(
        "2022-04-11 10:00:00",
        "2022-04-18 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 4;
    } else if (
      currentDate.value.isBetween(
        "2022-04-18 10:00:00",
        "2022-04-25 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 5;
    } else if (
      currentDate.value.isBetween(
        "2022-04-25 10:00:00",
        "2022-05-02 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 6;
    } else if (
      currentDate.value.isBetween(
        "2022-05-02 10:00:00",
        "2022-05-09 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 7;
    } else if (
      currentDate.value.isBetween(
        "2022-05-09 10:00:00",
        "2022-05-16 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 8;
    } else if (
      currentDate.value.isBetween(
        "2022-05-16 10:00:00",
        "2022-05-23 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 9;
    } else if (
      currentDate.value.isBetween(
        "2022-05-23 10:00:00",
        "2022-06-30 10:00:00",
        null,
        "[]"
      )
    ) {
      currentWeek.value = 11;
    }

    if (currentWeek.value == 0) {
      router.push("/activity");
    }

    document.addEventListener("scroll", function () {
      const header: any = document.querySelector(".header");
      var top = window.scrollY;
      top > 0
        ? header.classList.add("sticky")
        : header.classList.remove("sticky");

      document.getElementById("profile-info")?.classList.remove("show");
      document.getElementById("profile-info-m")?.classList.remove("show");
    });

    const showProfileInfo = () => {
      document.getElementById("profile-info")?.classList.toggle("show");
      document.getElementById("profile-info-m")?.classList.toggle("show");
    };

    const openMenu = (e: any) => {
      document.getElementById("mobile-menu")?.classList.toggle("active");
      document.getElementById("profile-area-m")?.classList.toggle("hide");
      document.getElementById("sub-menu")?.classList.toggle("show");
      document.body.classList.toggle("menu-open");
    };

    const toggleModal = (modalNo: number) => {
      document
        .querySelector(`#detail-modal-${modalNo}`)
        ?.classList.toggle("d-none");
      document.body.classList.toggle("modal-show");
    };

    const toggleContent = (e: any) => {
      let element = e.currentTarget as Element;
      element.parentElement?.classList.toggle("show");
    };

    const getUserProfileImage = async (party_id: string, abo_id: string) => {
      return axios
        .get("/api/external/amway/profile/image", {
          params: {
            abo_id: abo_id,
            party_id: party_id,
          },
        })
        .then((res: AxiosResponse) => {
          return res.data;
        })
        .catch((err: AxiosError) => {
          console.log("error", err.response);
          return null;
        });
    };

    const init = async () => {
      try {
        let { data } = await axios.get("/api/memberweek/team/score", {
          params: {
            weekno: currentWeek.value,
            party_id: store.state.userInfo.party_id,
            abo_id: store.state.userInfo.abo_id,
          },
        });

        finalWeek.value = data.weeks.find((w: any) => w.week_no == 11)

        if (finalWeek.value) {
          // hasFinalWeek.value = true;

          // // recal final week
          // let sum_final_score = 0
          // finalWeek.value.member_score.forEach((s: any) => {
          //   sum_final_score += (s.score.online_class + s.score.social_posting + s.score.sponsoring + s.score.online_class)
          // });

          // finalWeek.value.team_total_score = sum_final_score

          // // recal special rewards
          // let sum_special_score = 0
          // specialReward.value = Object.assign({}, finalWeek.value);
          // specialReward.value.member_score.forEach((s: any) => {
          //   sum_special_score += (s.score.body_key + s.score.social_join)
          // });

          // specialReward.value.team_total_score = sum_special_score
        } else {
          router.push("/activity"); 
        }

        team.value = data;
        currentRank.value = data.weeks[data.weeks.length - 1].rank

        team.value.weeks.forEach((w: any) => {
          w.member_score.forEach(async (m: any) => {
            m.image = await getUserProfileImage(m.party_id, m.abo_id);
          });
        });
      } catch (error) {}
    };

    init();

    return {
      showProfileInfo: showProfileInfo,
      getUserProfileImage: getUserProfileImage,
      openMenu: openMenu,
      toggleModal: toggleModal,
      toggleContent: toggleContent,
      weekTxt: weekTxt,
      currentDate: currentDate,
      currentRank: currentRank,
      finalWeek: finalWeek,
      hasFinalWeek: hasFinalWeek,
      specialReward: specialReward,
      dayjs: dayjs,
      team: team,
    };
  },
});
